/**
 * Created by Md.Abdullah Al Mamun.
 * Project: BoardMeeting
 * File:
 * Email: mamun@infosapex.com, dev.mamun@gmail.com
 * Date: 3/12/19
 */

class Auth {
    constructor() {
        this._api = {};
        this._loggedIn = {};
        this._otp = {};
        this._url = "";
        this._method = "GET";
        this._inputs = {};
    };
    
    set api($val) {
        this._api = $val;
    }
    
    get api() {
        return this._api;
    };
    
    set loggedIn($val) {
        this._loggedIn = $val;
    }
    
    get loggedIn() {
        return this._loggedIn;
    };
    
    set otp($val) {
        this._otp = $val;
    }
    
    get otp() {
        return this._otp;
    };
    
    login() {
        var $this = this;
        $(document).on('click', '.btn-login', function (event) {
            event.preventDefault();
            var $loginFrm = $('form.login');
            var $parsley = $loginFrm.parsley().validate();
            if ($parsley) {
                $this._url = $this.api.login;
                $this._method = "POST";
                $this._inputs = $loginFrm.serialize();
                var $promises = $this.httpRequest();
                $promises.done(function (response) {
                    if (response.status) {
                        $this.loggedIn = response;
                        if (response.user.twofa && response.settings.twofa) {
                            $this.sendOtp();
                        } else {
                            $this.setCookies();
                        }
                    }
                });
            }
        });
    };
    
    sendOtp() {
        var $this = this;
        $this._url = $this.api.otp;
        $this._method = "GET";
        $this._inputs = {};
        var $promises = $this.httpRequest();
        $promises.done(function (response) {
            if (response.status) {
                $("#login").addClass('hidden');
                $("#otp").removeClass('hidden');
                toastr.success(response.msg, 'OTP');
                $this.otp = response;
                $this.verifyOtp();
            } else {
                toastr.error(response.msg, 'OTP');
            }
        });
    };
    
    verifyOtp() {
        var $this = this;
        $(document).on('click', '.btn-verify', function (event) {
            event.preventDefault();
            var $otpFrm = $('form.otp');
            var $parsley = $otpFrm.parsley().validate();
            if ($parsley) {
                $this._url = $this.api.verify;
                $this._method = "POST";
                $this._inputs = $otpFrm.serialize()+"&code="+$this.otp.code;
                var $promises = $this.httpRequest();
                $promises.done(function (response) {
                    $otpFrm[0].reset();
                    $otpFrm.parsley().reset();
                    if(response.status){
                        toastr.success(response.msg, 'OTP Verify');
                        $this.setCookies();
                    }else{
                        toastr.error(response.msg, 'OTP Verify');
                    }
                });
            }
        });
    };
    
    setCookies(){
        var $this = this;
        Cookies.set('authToken', $this.loggedIn.token, {expires: 1});
        if ($this.loggedIn.settings) {
            Cookies.set('has_category', Number($this.loggedIn.settings.category), {expires: 1});
            Cookies.set('has_department', Number($this.loggedIn.settings.department), {expires: 1});
        }
        window.location.replace($this.loggedIn.route);
    }
    
    httpRequest() {
        var $this = this;
        console.log('httpRequest: ',$this._inputs);
        return $.ajax({
            url: $this._url,
            type: $this._method,
            data: $this._inputs,
            beforeSend: function (xhr) {
                if ($this._loggedIn != "") {
                    xhr.setRequestHeader("Authorization", "Bearer " + $this._loggedIn.token)
                }
            },
            statusCode: {
                401: function () {
                    toastr.error("Your login session has been expired!", 'Login');
                },
                400: function () {
                    toastr.error("Invalid login! please login again.", 'Login');
                }
            },
            error: function (event, textStatus, thrownError) {
                toastr.error(event.responseJSON.msg, 'Login');
            },
        });
    };
}
